import React from "react"
import styled from "styled-components"

const SocialIconLink = styled.a`
  transition: filter 0.2s ease;
  & + & {
    display: inline-block;
    margin-left: 20px;
  }
  img {
    margin-bottom: 0;
  }
  :hover {
    filter: saturate(1.6) hue-rotate(120deg) brightness(2.1);
  }
`

const SocialIcon = ({ src, url, alt, ...props }) => {
  return (
    <SocialIconLink
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      {...props}
    >
      <img src={src} alt={alt} />
    </SocialIconLink>
  )
}

export default SocialIcon
