import React, { forwardRef } from "react"
import Img from "gatsby-image"

const BaseImg = forwardRef(({ localFile, alt, ...props }, ref) => {
  return localFile ? (
    localFile.childImageSharp ? (
      localFile.childImageSharp.fluid ? (
        <Img
          ref={ref}
          fluid={localFile.childImageSharp.fluid}
          alt={alt}
          {...props}
        />
      ) : (
        <Img
          ref={ref}
          fixed={localFile.childImageSharp.fixed}
          alt={alt}
          {...props}
        />
      )
    ) : (
      <img ref={ref} src={localFile.publicURL} alt={alt} {...props} />
    )
  ) : null
})

export default BaseImg
