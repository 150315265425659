import React from "react"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import styled from "styled-components"
import { Link } from "gatsby"
const MenuWrapper = styled.ul`
  list-style: none;
  margin: 0;
  text-transform: lowercase;
`

const FooterMenu = ({ menuEntries }) => {
  return (
    <MenuWrapper>
      {menuEntries.map((menuEntry) => (
        <li style={{ margin: "0", whiteSpace: "nowrap" }} key={menuEntry.title}>
          <Link
            to={decodeHtmlCharCodes(menuEntry.url).replace(
              /http.*\/\/.*?\//g,
              "/"
            )}
          >
            {decodeHtmlCharCodes(menuEntry.title)}
          </Link>
        </li>
      ))}
    </MenuWrapper>
  )
}
export default FooterMenu
