import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "./styledComponents"
import FooterMenu from "./footerMenu"
import SocialIcon from "../components/socialIcon"
import { joinMenuToLocation } from "../helpers"

const Header2 = styled.h2`
  font: 700 0.875rem/1.125rem "Montserrat";
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  @media (max-width: 1195px) {
    white-space: normal;
  }
`

const TextLink = styled.a`
  font-weight: 700;
`

const Footer = styled.footer`
  font: 500 0.875rem/1.625rem "Hind";
  margin-top: 76px;
  padding-bottom: 76px; /* Need padding here because body's background image needs to be full height */
  @media screen and (max-width: 1195px) {
    text-align: center;
  }
`

const FooterFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1195px) {
    flex-direction: column;
    align-items: center;
  }
`

const SocialIconsWrapper = styled.div`
  display: flex;
  height: 34px;
  margin-top: 0.875rem;
  @media screen and (max-width: 1195px) {
    justify-content: center;
  }
`

const BlueowlLogo = styled.img`
  display: block;
  height: 1.5rem;
  margin-bottom: 0.875rem;
  @media screen and (max-width: 1195px) {
    margin: 0 auto 0.875rem;
  }
`

const LastFooterColumn = styled.div`
  margin: 24px 0 24px 32px;
  max-width: 300px;
  @media (max-width: 1195px) {
    margin: 24px 0;
  }
`

const PageFooter = () => {
  const {
    allFile,
    allWordpressPage,
    allWordpressWpApiMenusMenuLocations,
    allWordpressWpApiMenusMenusItems,
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: {
            in: [
              "logo"
              "dribbbleButton"
              "facebookButton"
              "instaButton"
              "linkedinButton"
            ]
          }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      allWordpressPage(
        filter: { template: { eq: "page-templates/page-global-settings.php" } }
      ) {
        nodes {
          cmb2 {
            metabox_global_settings {
              address1
              address2
              companyName
              contactMail
              contactMailPrefix
              contactMobile
              contactMobilePrefix
              footerRightText
              footerRightTitle
              facebook
              instagram
              dribbble
              linkedin
            }
          }
        }
      }
      allWordpressWpApiMenusMenuLocations(
        sort: { fields: label }
        filter: { label: { in: ["Services", "Portfolio", "Site structure"] } }
      ) {
        nodes {
          label
          wordpress_id
        }
      }
      allWordpressWpApiMenusMenusItems {
        nodes {
          name
          items {
            title
            url
          }
          wordpress_id
        }
      }
    }
  `)
  const logo = allFile.nodes.find((file) => file.name === "logo")
  const dribbbleButton = allFile.nodes.find(
    (file) => file.name === "dribbbleButton"
  )
  const instaButton = allFile.nodes.find((file) => file.name === "instaButton")
  const facebookButton = allFile.nodes.find(
    (file) => file.name === "facebookButton"
  )
  const linkedinButton = allFile.nodes.find(
    (file) => file.name === "linkedinButton"
  )
  const {
    address1,
    address2,
    companyName,
    contactMail,
    contactMailPrefix,
    contactMobile,
    contactMobilePrefix,
    facebook,
    instagram,
    dribbble,
    linkedin,
    footerRightText,
    footerRightTitle,
  } = allWordpressPage.nodes[0].cmb2.metabox_global_settings

  const portfolioMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[0],
    allWordpressWpApiMenusMenusItems.nodes
  )
  const servicesMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[1],
    allWordpressWpApiMenusMenusItems.nodes
  )
  const siteStructureMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[2],
    allWordpressWpApiMenusMenusItems.nodes
  )

  return (
    <Footer>
      <Container>
        <FooterFlex>
          <div style={{ margin: "24px 0" }}>
            <BlueowlLogo src={logo.publicURL} alt="Blueowl logo" />
            <TextLink href="/">{companyName}</TextLink>
            <br />
            {address1}
            <br />
            {address2}
            <br />
            {`${contactMailPrefix} `}
            <TextLink href={`mailto:${contactMail}`}>{contactMail}</TextLink>
            <br />
            {`${contactMobilePrefix} `}
            <TextLink href={`tel:${contactMobile}`}>{contactMobile}</TextLink>
            <SocialIconsWrapper>
              <SocialIcon
                src={facebookButton.publicURL}
                alt="Facebook logo"
                url={facebook}
              />
              <SocialIcon
                src={instaButton.publicURL}
                alt="Instagram logo"
                url={instagram}
              />
              <SocialIcon
                src={linkedinButton.publicURL}
                alt="LinkedIn logo"
                url={linkedin}
              />
              <SocialIcon
                src={dribbbleButton.publicURL}
                alt="Dribbble logo"
                url={dribbble}
              />
            </SocialIconsWrapper>
          </div>

          <div style={{ margin: "24px 32px" }}>
            <Header2>{siteStructureMenu.name}</Header2>
            <FooterMenu menuEntries={siteStructureMenu.items} />
          </div>

          <div style={{ margin: "24px 32px" }}>
            <Header2>{servicesMenu.name}</Header2>
            <FooterMenu menuEntries={servicesMenu.items} />
          </div>

          <div style={{ margin: "24px 32px" }}>
            <Header2>{portfolioMenu.name}</Header2>
            <FooterMenu menuEntries={portfolioMenu.items} />
          </div>

          <LastFooterColumn>
            <Header2>{footerRightTitle}</Header2>
            <p dangerouslySetInnerHTML={{ __html: footerRightText }}></p>
            <iframe
              loading="lazy"
              title="clutch widget"
              id="iframe-0.0487214283066153"
              frameBorder="0"
              width="180"
              height="50"
              scrolling="no"
              src="https://widget.clutch.co/widgets/get/2?uid=409086"
            ></iframe>
          </LastFooterColumn>
        </FooterFlex>
      </Container>
    </Footer>
  )
}
export default PageFooter
